import React from 'react'
import Img from 'gatsby-image'
import HoverIcon from '../general/HoverIcon';
export default function BackPacketModule({ image }) {

    const icons = [
        {
            location: {
                top: "12%",
                left: "75%"
            },
            number: 1,
            header: "Our Promise",
            description: "Good Karma Wide, Title case",
            position: "left"
        },
        {
            location: {
                top: "20%",
                left: "40%"
            },
            number: 2,
            header: "Claims",
            description: "Atrament Semibold, Uppercase"
        },
        {
            location: {
                top: "21%",
                left: "2%"
            },
            number: 3,
            header: "Cooking Instructions",
            description: "Filson Soft Regular & Bold, Sentence case",
     
        }
    ]
    return (
        <section className="static-module">
        <h3>Back of pack</h3>
        <div className=" bg-knorr-light py-12 lg:py-20 ">
            <div className="w-full h-full max-w-xl px-4 mx-auto relative">
                <Img className="w-full h-full" fluid={image.fluid} />
                {icons.map(icon => <HoverIcon key={icon.number} {...icon} />)}
            </div>
        </div>
    </section>
    )
}

