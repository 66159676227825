import React from 'react'
import { graphql } from 'gatsby';
import { useElements } from '../../utils/useElements';

export default function ThreeColumnsModule(props) {

    const { columnOne, columnTwo, columnThree } = props;



    const columnProps = {
        headerElement: {
            className: "text-4xl "
        },
        imageElement: {
            className: "mx-auto max-w-xxs",
            wrapperClassName: "flex-grow",
        }, 
        buttonElement: {
            className: "mx-auto"
        }
    }

    const columnClasses = `space-y-4 p-6 py-8 bg-knorr bg-opacity-20 flex flex-col justify-between`

    const ColumnOne = useElements(columnOne, columnProps)
    const ColumnTwo = useElements(columnTwo, columnProps)
    const ColumnThree = useElements(columnThree, columnProps)

    return (
        <section className="grid lg:grid-cols-3 gap-x-6 text-center">

            <div className={columnClasses}>
                <ColumnOne />
            </div>
            <div className={columnClasses}>
                <ColumnTwo />
            </div>

            <div className={columnClasses}>
                <ColumnThree />
            </div>
        </section>
    )
}


export const ThreeColumnsModuleFragment = graphql`
     fragment ThreeColumnsModuleFragment on ContentfulThreeColumnsModule {
        id
        name
        columnOne {
            ...ButtonElementFragment
            # ...SubHeaderElementFragment
            ...HeaderElementFragment
            ...PreHeaderElementFragment
            ...ImageElementFragment
        }
        columnTwo {
            ...ButtonElementFragment
            # ...SubHeaderElementFragment
            ...HeaderElementFragment
            ...PreHeaderElementFragment
            ...ImageElementFragment
        }
        columnThree {
            ...ButtonElementFragment
            # ...SubHeaderElementFragment
            ...HeaderElementFragment
            ...PreHeaderElementFragment
           
            ...ImageElementFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
