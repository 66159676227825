import React from 'react'
import { graphql } from 'gatsby';
import FrontPacketModule from '../static-modules/FrontPacketModule';
import BackPacketModule from '../static-modules/BackPacketModule';
import FryingPanModule from '../static-modules/FryingPanModule';
export default function StaticContentModule({ staticType, image, fryingPanData  }) {

    const type = staticType.find( a => a )

    if (type === "Front Packet Hover")  return  <FrontPacketModule image={image} />
    if (type === "Back Packet Hover")   return  <BackPacketModule image={image} />
    if (type === "Frying Pan Hover")   return  <FryingPanModule image={image} fryingPanData={fryingPanData} />

    return (<></>)
}


export const StaticContentModuleFragment = graphql`
     fragment StaticContentModuleFragment on ContentfulStaticContentModule {
        id
        name
        staticType
        fryingPanData {
            pans {
                id
                link
          }
        }
        image {
            ...GatsbyFluidImageFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`


