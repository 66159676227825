import React from 'react'
import { graphql } from 'gatsby';
import { useElements } from '../../utils/useElements';
import Img from 'gatsby-image';

export default function QuoteModule(props) {
    const { header, quote, headshot, personName, link } = props;


    const Link = useElements(link, { className: "mx-auto" })

    return (
        <section data-aos="fade-up" data-aos-duration="1500" className=" text-center quote-module rounded ">
            <div className="rounded py-12 px-4  max-w-screen-lg mx-auto bg-gradient-to-t from-lime via-knorr to-knorr text-white ">
                <h4 className="uppercase text-2xl mb-20">{header}</h4>
                <div className="mb-12">
                    <p className="text-3xl md:text-5xl leading-tight ">{quote.text}</p>
                    <Img className="w-32 mx-auto my-4" fluid={headshot.fluid} />
                    <h5 className="text-xl font-normal">{personName}</h5>
                </div>


                <Link />
            </div>

        </section>
    )
}


export const QuoteModuleFragment = graphql`
     fragment QuoteModuleFragment on ContentfulQuoteModule {
        id
        header
        quote {
            text: quote
        }
        headshot: person {
            ...GatsbySmallFluidImageFragment
        }
        personName: name 
        link {
            ...ButtonElementFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
