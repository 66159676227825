import React from 'react'
import { graphql } from 'gatsby';
import { useElements } from '../../utils/useElements';
import DownArrow from '../../images/down-arrow-tail.svg'
import Img from 'gatsby-image'
import ErrorBoundary from '../general/ErrorBoundary';



const workingWindow = typeof window !== "undefined" ? window : {}

export default function PackageAssemblyModule(props) {


    const { header, stages } = props;


    const firstStage = stages[0]


    const ImageRef = React.useRef()
    const ContainerRef = React.useRef()
    const SectionRef = React.useRef()


    const [height, setHeight] = React.useState(400)
    const [activePackage, setActivePackage] = React.useState(0)



    React.useEffect(() => {

        findHeight()
        window.onresize = findHeight
        window.onscroll = handleScroll

        return () => {
            window.onresize = null
            window.onscroll = null
        }
    }, [workingWindow.innerWidth])


    function findHeight() {
        return setHeight(ImageRef.current?.clientHeight)
    }

    const handleScroll = () => {

        const scroll = workingWindow.scrollY


        const scrollHeight = SectionRef.current.clientHeight
        const offSetTop = SectionRef.current.offsetTop

        const scrollTop =  scroll - offSetTop



        const enteredContainer = workingWindow.scrollY > offSetTop
        const leftContainer = workingWindow.scrollY > ((offSetTop + scrollHeight) - workingWindow.innerHeight)

        const spaceToScroll = ((offSetTop + scrollHeight) - workingWindow.innerHeight) - offSetTop
        // console.log(spaceToScroll, scrollTop)
        // console.log(`Entered Container: ${enteredContainer} | Left Container: ${leftContainer}`)

        const section = spaceToScroll / (stages.length - 1)

        const activeSlide = Math.floor(scrollTop / section) || 0

        if (activePackage !== activeSlide) {
            return setActivePackage(leftContainer ? stages.length -1 :  !enteredContainer ? 0 : activeSlide)
        }

        return
    }

    return (
        <ErrorBoundary onError={<ErrorVersion image={stages[stages.length - 1].image} />}>
            <section
                ref={SectionRef}
                className="lg:-mt-32 "
                style={{ height: `${20 * stages.length - 1}rem` }}
            >
                <div className="sticky top-0 w-full pr-4">
                    <div className="z-0 h-screen flex flex-col justify-center ">
                        <div className="text-center mb-4 sticky top-0">
                            <img className="mx-auto mb-2 animate-bounce" src={DownArrow} alt="Down Arrow" />
                            <h4 className="font-bold text-xl">SCROLL</h4>
                            <h3>{header}</h3>
                        </div>
                        <figure className="grid lg:grid-cols-7 grid-cols-1" >
                            <div className="lg:col-span-2 flex flex-row lg:flex-col h-full justify-center lg:justify-around">
                                {stages.map((stage, index) => <Header
                                    className={`${index === activePackage ? "scale-100 font-bold opacity-1" : "scale-90 opacity-75"} transform`}
                                    index={index}
                                    header={stage.header}
                                />)}
                            </div>
                            <div className="col-span-3 w-full relative max-w-xl mx-auto z-0 " style={{ height: height }}>

                                <div ref={ImageRef} className={` mx-auto py-4 relative z-10`} >
                                    <Img fluid={firstStage.image.fluid} />
                                </div>

                                {stages.map((stage, index) => {
                                    return index !== 0 && <div
                                        className={`absolute  top-0 w-full h-full mx-auto py-4 
                                        ${activePackage === index ? "opacity-1 z-10" : "opacity-0 z-0"} `}
                                        style={{ 
                                                height, 
                                            // animation: activePackage === index && "fadeIn 1s" 
                                             }} >
                                        <Img fluid={stage.image.fluid} title={stage.image.title} />
                                    </div>
                                })}

                            </div>
                            <figcaption className="flex flex-col lg:col-span-2 justify-center relative">
                                {stages.map((stage, index) => {

                                    return  <p 
                                        className={`
                                            hidden lg:block absolute text-base xl:text-xl w-full transition duration-300
                                            ${activePackage === index ? "opacity-1 z-40 " : "opacity-0 z-0"} 
                                            `} 
                                            >
                                                {stage.description.text}
                                            </p>
                                })}
                                {stages.map((stage, index) => 
                                    <p 
                                    className={`
                                        -ml-3 lg:hidden absolute text-2xl w-full text-center transition-all duration-300 top-0
                                        ${activePackage === index ? "opacity-1 z-40 " : "opacity-0 z-0"} 
                                        `} 
                                    >
                                        {stage.header}
                                    </p>)}

                            </figcaption>
                        </figure>
                    </div>
                </div>
            </section>
        </ErrorBoundary>
    )
}


export const PackageAssemblyModuleFragment = graphql`
     fragment PackageAssemblyModuleFragment on ContentfulPackageAssemblyModule {
        id
        header
        stages {
            ...PackagingStageFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`


const ErrorVersion = ({ image }) => {
    return <section className="" >

        <div className="max-w-xl mx-auto ">
            <Img fluid={image.fluid} />
        </div>

    </section>
}


const Header = ({ header, index, ...restProps }) =>
    <h5
        {...restProps}
        className={`text-xl lg:text-2xl xl:text-3xl  ${restProps.className}`}>
        <span
            className=" w-8 h-8 lg:w-12 lg:h-12 xl:mr-4 mr-2 text-center inline-flex flex-col justify-center rounded-full text-white bg-knorr">
            {index + 1}
        </span>

        <span className="hidden lg:inline-block absolute w-full mt-2 xl:mt-0">
            {header}
        </span>

    </h5>
