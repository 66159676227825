import React from 'react'
import AssetModule from '../components/contentful-modules/AssetModule';
import CallToActionModule from '../components/contentful-modules/CallToActionModule';
import ContentModule from '../components/contentful-modules/ContentModule';
import DiscoveryModule from '../components/contentful-modules/DiscoveryModule';
import DoAndDontModule from '../components/contentful-modules/DoAndDontModule';
import FaqsModule from '../components/contentful-modules/FaqsModule';
import FullWidthImageModule from '../components/contentful-modules/FullWidthImageModule';
import HeroModule from '../components/contentful-modules/HeroModule';
import PackageAssemblyModule from '../components/contentful-modules/PackageAssemblyModule';
import QuoteModule from '../components/contentful-modules/QuoteModule';
import SummaryModule from '../components/contentful-modules/SummaryModule';
import ThreeColumnsModule from '../components/contentful-modules/ThreeColumnsModule';
import ThreePillarsModule from '../components/contentful-modules/ThreePillarsModule';
import TwoColumnsModule from '../components/contentful-modules/TwoColumnsModule';
import TwoColumnsSpreadModule from '../components/contentful-modules/TwoColumnsSpreadModule';
import VideoModule from '../components/contentful-modules/VideoModule';
import StaticContentModule from '../components/contentful-modules/StaticContentModule';

const modulesReferences = {
    assetModule: AssetModule,
    callToActionModule: CallToActionModule,
    contentModule: ContentModule,
    discoveryModule: DiscoveryModule,
    doAndDontModule: DoAndDontModule,
    fullWidthImageModule: FullWidthImageModule,
    heroModule: HeroModule,
    packageAssemblyModule: PackageAssemblyModule,
    quoteModule: QuoteModule,
    staticContentModule: StaticContentModule,
    summaryModule: SummaryModule,
    threeColumnsModule: ThreeColumnsModule,
    threePillarsModule: ThreePillarsModule,
    twoColumnsModule: TwoColumnsModule,
    twoColumnsSpreadModule: TwoColumnsSpreadModule,
    videoModule: VideoModule,
    faqsModule: FaqsModule
}


const useModules = (mods, options = {}) => {


    const getHeroModule = (modules) => {
        return Array.isArray(modules) ? modules.filter(
            module => module.type === "heroModule"
        ).map(module => createModule(module)) : []
    }

    const createModule = (module) => {
        const Module = modulesReferences[module.type]
        const id = module._id
        // return a function so that it can be rendered with JSX
        // then pass the module in as an arg which is converted to props in the JSX 
        return {
            Module: (props) => Module({ ...props, ...module }),
            id,
        }
    }






    const heroModule = getHeroModule(mods)

    const filteredModules = mods.filter(
        module => module.type !== "heroModule"
    )
    const modulesToRender = filteredModules.map(module => createModule(module))

    if (options.getHeroModule) {
        return [modulesToRender, heroModule]
    }
    return [...heroModule, ...modulesToRender]



}




export default useModules