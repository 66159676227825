import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby';
import useElements from '../../utils/useElements';

const FaqsModule = ({ faqs, header, id }) => {

    const FAQsElements = useElements(faqs)
    return (
        <section className="space-y-8">
            <h3 className="text-5xl text-cta">{header}</h3>
           <FAQsElements />
        </section>
    )
}


export const FaqsModuleFragment = graphql`
     fragment FaqsModuleFragment on ContentfulFaqsModule {
        id
        header
        faqs {
            ...QuestionAnswerElementFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`



export default FaqsModule
