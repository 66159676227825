import React from 'react'
import { graphql } from 'gatsby';
import tickIcon from '../../images/tick-icon.svg'
import crossIcon from '../../images/cross-icon.svg'

export default function DoAndDontModule(props) {

    const { dos, donts } = props;


    // Render map over the arrays of strings
    return (
        <section className="do-dont flex md:flex-row flex-col bg-knorr text-white py-8 pr-4 ">
            <div className="md:w-1/2 flex pl-4">
                <img className="self-start w-8 pt-2 " src={tickIcon} alt="Tick Icon" />
                <ul className="bullets">
                    {dos && dos.map((doText, index) => <li key={doText + `${index}`}>{doText}</li>)}
                </ul>
            </div>
            <div className="md:w-1/2 flex pl-4">
                <img className="self-start w-8 pt-2" src={crossIcon} alt="Cross Icon" />
                <ul className="bullets">
                    {donts && donts.map((dontText, index) => <li key={dontText + `${index}`}>{dontText}</li>)}
                </ul>
            </div>
        </section>
    )
}


export const DoAndDontModuleFragment = graphql`
     fragment DoAndDontModuleFragment on ContentfulDoAndDontModule {
        id
        name
        dos
        donts
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
