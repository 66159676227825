import React from 'react'
import { graphql } from 'gatsby';
import useElements from '../../utils/useElements';
import Img from 'gatsby-image'

export default function ThreePillarsModule({ pillarOne, pillarOneBackground, pillarTwo, pillarTwoBackground, pillarThree, pillarThreeBackground }) {

    const pillarProps = {
        headerElement: {
            className: "text-4xl text-white mb-4",
            noMenu: true
        },
        subHeaderElement :{
            className: "text-sm h-48",
            noMenu: true
        },
        preHeaderElement: {
            className: "font-normal text-base text-white"
        },
        imageElement: {
            className: "mx-auto max-w-xxs my-8",
            wrapperClassName: "flex-grow",
            caption: false
        },
        buttonElement: {
            className: "mx-auto "
        },
        richTextElement: {
            className: "flex-grow flex flex-col justify-start h-full text-white"
        }
    }

    const pillarClasses = `h-full relative `

    const PillarOne = useElements(pillarOne, pillarProps)
    const PillarTwo = useElements(pillarTwo, pillarProps)
    const PillarThree = useElements(pillarThree, pillarProps)

    const PillarWrapper = (props) => <div className="pillars relative overflow-hidden flex flex-col h-full px-4 lg:px-6 py-8 z-10">{props.children}</div>
    const ImgWrapper = (props) => <figure className="absolute w-full h-full top-0 left-0" >{props.children}</figure>

    return (
        <section className="relative z-0 grid md:grid-cols-3 text-center text-white">

            <div className={pillarClasses}>
                {pillarOneBackground && <ImgWrapper><Img className="h-full " fluid={pillarOneBackground.fluid} alt={pillarOneBackground.title} /> </ImgWrapper>}

                <PillarWrapper>
                    <PillarOne />
                </PillarWrapper>



            </div>
            <div className={pillarClasses}>
                {pillarTwoBackground && <ImgWrapper><Img className="h-full" fluid={pillarTwoBackground.fluid} alt={pillarTwoBackground.title} /> </ImgWrapper>}


                <PillarWrapper>
                    <PillarTwo />
                </PillarWrapper>


            </div>

            <div className={pillarClasses}>
                {pillarThreeBackground && <ImgWrapper><Img className="h-full" fluid={pillarThreeBackground.fluid} alt={pillarThreeBackground.title} /> </ImgWrapper>}

                <PillarWrapper>
                    <PillarThree />
                </PillarWrapper>

            </div>
        </section>
    )
}

export const ThreePillarsModuleFragment = graphql`
     fragment ThreePillarsModuleFragment on ContentfulThreePillarsModule {
        id
        name
        pillarOne {
            ...ButtonElementFragment
            ...HeaderElementFragment
            ...PreHeaderElementFragment
            ...SubHeaderElementFragment
            ...RichTextElementFragment
            ...ImageElementFragment
        }
        pillarOneBackground {
            ...GatsbyFluidImageFragment
        }
        pillarTwo {
            ...ButtonElementFragment
            ...HeaderElementFragment
            ...PreHeaderElementFragment
            ...SubHeaderElementFragment
            ...RichTextElementFragment
            ...ImageElementFragment
        }
        pillarTwoBackground {
            ...GatsbyFluidImageFragment
        }
        pillarThree {
            ...ButtonElementFragment
            ...HeaderElementFragment
            ...PreHeaderElementFragment
            ...SubHeaderElementFragment
            ...RichTextElementFragment
            ...ImageElementFragment
        }
        pillarThreeBackground {
            ...GatsbyFluidImageFragment
        }
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
