import React from 'react'
import Img from 'gatsby-image'
import KnorrLogo from '../../images/knorr-logo-no-bg.svg'
import FryingPan from '../../images/frying-pan.svg'
import SaucePan from '../../images/sauce-pan.svg'
import ToolTip from '../general/ToolTip';
import ButtonElement from '../contentful-elements/ButtonElement'

export default function FryingPanModule({ image, fryingPanData }) {

    const { pans } = fryingPanData

    const items = [
        {
            header: <>Functional <br /> Benefits</>,
            id: "Functional Benefits",
            description: <>Superior Sustainable Flavour <br /> The guaranteed great taste that comes from natural ingredients grown and treated the right way
            </>,
            link: pans.filter(pan => pan.id === "Functional Benefits")[0]?.link
        },
        {
            header: <>Brand <br /> Discriminator</>,
            id: "Brand Discriminator",
            description: <>
                Only with Knorr can you create flavourful food every day that is good for you and good for our planet
            </>,
            link: pans.filter(pan => pan.id === "Brand Discriminator")[0]?.link
        },
        {
            header: <>Brand <br /> Personality</>,
            id: "Brand Personality",
            description: <>
                Disarmingly Honest <br />
                Optimistic <br />
                Inviting <br />
                Considerate
            </>,
            link: pans.filter(pan => pan.id === "Brand Personality")[0]?.link
        },
        {
            header: <>Emotional <br /> Benefits</>,
            id: "Emotional Benefits",
            description: <>
                Feel good about your food choices <br />
                The pleasure of enjoying flavourful nutritious food while doing greater good
            </>,
            link: pans.filter(pan => pan.id === "Emotional Benefits")[0]?.link
        },
        {
            header: <>Product <br /> Truth</>,
            id: "Product Truth",
            description: <>
                Authentic flavour from natural and nutritious ingredients which are grown sustainably and produced responsibly with respect for the land
            </>,
            link: pans.filter(pan => pan.id === "Product Truth")[0]?.link
        },
        {
            header: <>The people <br /> we serve</>,
            id: "The People We Serve",
            description: <>
                People with an open outlook who are looking to be their best selves and in doing so have a positive impact and help make the world around them a better place too
            </>,
            link: pans.filter(pan => pan.id === "The People We Serve")[0]?.link
        },
        {
            header: <>Roots</>,
            id: "Roots",
            description: <>
                Experts in authentic flavours since 1838 <br />
                Chefmanship – Recipes crafted by Knorr’s team of Master Chefs. <br />
                Green: A symbol of naturalness and simplicity <br />
                Local expertise with global reach
            </>,
            link: pans.filter(pan => pan.id === "Roots")[0]?.link
        },
        {
            header: <>Human <br /> truth</>,
            id: "Human Truth",
            description: <>
                We’re increasingly aware that we do not live in a bubble, that the world is interconnected and our choices have an impact in ‘my world’ and also in the world beyond
            </>,
            link: pans.filter(pan => pan.id === "Human Truth")[0]?.link
        },

    ]




    // Correspond to the index of the pans when rendered
    const panClasses = [
        "mt-80 p-5",
        "mt-0 ",
        "p-4",
        "mt-80",
        "-mt-80",
        " p-6",
        "p-12",
        "-mt-80 p-5"
    ]



    return (
        <div className="relative z-0 w-full h-full px-2 py-12">
            <div className="relative z-30 w-full h-full">
                <div className="hidden  lg:grid max-w-screen-lg grid-cols-2 gap-4 mx-auto  md:grid-cols-3 lg:grid-cols-4 -mt-32">
                {items.map((tooltip, index) => index < 4 && 
                <Pan key={index} className={`${panClasses[index]}`} {...tooltip} />)}
                    <ToolTip
                        overlay={<HoverOver 
                            id="Sustainable Living Purpose"
                            header="Sustainable Living Purpose"
                            description="Reinventing Food for Humanity"
                            link={
                                pans.filter(pan => pan.id === "Sustainable Living Purpose")[0].link
                            }
                            />}
                        className="relative flex justify-center col-span-2 mx-auto md:col-span-1 lg:col-span-4 -mt-24"
                    >
                        {/* Center Frying Pan */}
                        <a
                            data-aos="zoom-in"
                            data-aos-duration="1000"
                            data-aos-offset="100"
                            data-tip data-for="Sustainable Living"
                            className="relative flex justify-center col-span-2 mx-auto md:col-span-1 lg:col-span-4">
                            <div className="absolute flex flex-col self-center cursor-pointer top-24 md:top-16 lg:top-20 xl:top-24 ">
                                <img className="self-center w-40 mb-4 xl:mb-6" src={KnorrLogo} alt="Knorr Logo" />
                                <h5 className="text-xl text-center text-white uppercase lg:text-2xl">Sustainable <br />Living Purpose</h5>
                            </div>
                            <img className="self-center md:w-64 lg:w-72 xl:w-80" src={SaucePan} alt="Saucepan " />
                        </a>
                    </ToolTip>
                    {items.map((tooltip, index) => index > 3 && 
                        <Pan 
                            key={index} 
                            className={`${panClasses[index]}`} 
                            {...tooltip} 
                        />)}

                </div>



                <div className="lg:hidden grid max-w-screen-xl grid-cols-2 gap-4 mx-auto  md:grid-cols-3 lg:grid-cols-4">
                    {items.map((tooltip, index) => index < 4 && <Pan key={index} {...tooltip} />)}

                    <ToolTip
                        overlay={<HoverOver 
                            id="Sustainable Living Purpose"
                            header="Sustainable Living Purpose"
                            description="Reinventing Food for Humanity" 
                            link={
                                pans.filter(pan => pan.id === "Sustainable Living Purpose")[0].link
                            }
                            />}
                        className="relative flex justify-center col-span-2 mx-auto md:col-span-1 lg:col-span-4"
                    >
                        {/* Center Frying Pan */}
                        <a
                            data-aos="zoom-in"
                            data-aos-duration="1000"
                            data-aos-offset="100"
                            data-tip data-for="Sustainable Living"
                            className="relative flex justify-center col-span-2 mx-auto md:col-span-1 lg:col-span-4">
                            <div className="absolute flex flex-col self-center cursor-pointer top-24 md:top-16 lg:top-20 xl:top-24 ">
                                <img className="self-center w-40 mb-4 xl:mb-6" src={KnorrLogo} alt="Knorr Logo" />
                                <h5 className="text-xl text-center text-white uppercase lg:text-2xl">Sustainable <br />Living Purpose</h5>
                            </div>
                            <img className="self-center md:w-64 lg:w-72 xl:w-80" src={SaucePan} alt="Saucepan " />
                        </a>
                    </ToolTip>
                    {items.map((tooltip, index) => index > 3 && <Pan {...tooltip} />)}

                </div>
            </div>

            <div className="absolute top-0 left-0 z-0 w-full h-full">
                <Img className="w-full h-full" fluid={image.fluid} />
            </div>
        </div>
    )
}

const Pan = ({ header, description, link, id, className }) => {


    const [show, setShow] = React.useState(false)

    return <div
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-offset="100"
        className={`relative flex self-center col-span-1 cursor-pointer mx-auto ${className}`}>
        <ToolTip 
            // visible={show} 
            overlay={
                <HoverOver 
                    header={id} 
                    description={description}
                    link={link} 
                />
            }>
            <div className="relative flex">
                <img 
                    className="self-center w-40 mx-auto lg:w-64 xl:w-64" 
                    src={FryingPan} 
                    alt="Frying pan outline" />
                <h5 className="absolute self-center inline-block w-full text-lg text-center text-white uppercase md:text-xl xl:text-2xl">{header}</h5>
                
            </div>
        </ToolTip>
    </div>
}


const HoverOver = ({ header, description, id = false, link }) => {
    return <div
        className={`w-full min-w-xs max-w-xs md:max-w-md border-none px-8 py-6 bg-transparent z-40 mx-2 bg-white rounded shadow-xl `}
        aria-haspopup='true'
        id={id ? id : header}
    >
        <h4 className="text-xl md:text-3xl text-knorr  font-bold">{header}</h4>
        <p className="text-lg text-knorr md:text-xl">{description}</p>
        { link && <ButtonElement text="Discover More" link={link} className="mt-4"/> }
    </div>
}