import React from 'react'
import { graphql } from 'gatsby';
import { useElements, getElementType } from '../../utils/useElements';

export default function CallToActionModule(props) {

    const { link, header } = props;

    const assetProps = { noDate: true, className: "max-w-md mx-auto", preText: "Download "}
    const buttonProps = { className:  "mx-auto"}

    const isButton = !!getElementType(link).buttonElement

    const Link = useElements(link, isButton ? buttonProps : assetProps )
    return (
        <section className="bg-cta py-12 grid grid-cols-1 gap-y-6 gap-x-6 md:grid-cols-2 px-4 md:px-12">
            <div className="leading-normal">
               <h3 className="text-4xl text-knorr font-bold">{header}</h3>
            </div>
            <div className="flex flex-col justify-center text-cent ">
               <Link />
            </div>
        </section>
    )
}


export const CallToActionModuleFragment = graphql`
     fragment CallToActionModuleFragment on ContentfulCallToActionModule {
        id
        header
        link {
            ...AssetElementFragment
            ...ButtonElementFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
