import { PropTypes } from 'prop-types';



export const TypeProp = { type: PropTypes.string.isRequired}
export const IdProp = { _id: PropTypes.string.isRequired}
export const HeaderProp = { header: PropTypes.string.isRequired}

export const PreHeaderProp = { preHeader: PropTypes.string}


export const SubHeaderRichTextProp = { 
    subHeader: PropTypes.shape({ 
        json: PropTypes.shape({
            content: PropTypes.array.isRequired,
            data: PropTypes.object.isRequired,
            nodeType: PropTypes.string.isRequired
        })
    })
}

export const FluidProp = {
    fluid: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        base64: PropTypes.string.isRequired,
        sizes: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
        srcSet:  PropTypes.string.isRequired,
        srcSetWebp:  PropTypes.string,
        srcWebp:  PropTypes.string
    })
}

export const FluidImageProp = { 
        title: PropTypes.string.isRequired, 
        ...FluidProp
}
