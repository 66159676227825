import React from 'react'
import { graphql } from 'gatsby';
import { PropTypes } from 'prop-types';
import { TypeProp, IdProp, FluidImageProp, HeaderProp, SubHeaderRichTextProp, PreHeaderProp } from '../../utils/PropTypes';
import RichTextElement from '../contentful-elements/RichTextElement';
import Img from 'gatsby-image';
import PreHeaderElement from '../contentful-elements/PreHeaderElement';
import RightArrow from '../svgs/RightArrow';

export default function HeroModule(props) {
    const { preHeader, header, subHeader, backgroundImage, arrow = false, objectPosition } = props;
    return (
        <div className="relative max-w-screen hero-module  no-scroll-bars md:max-h-screen" style={{ zIndex: 50, height: "135vh" }} >
            <div className="sticky top-0 h-screen ">
                <Img 
                    className=" left-0 z-0 h-screen " 
                    imgStyle={{
                        objectPosition: objectPosition
                     }}
                    fluid={backgroundImage.fluid} />
            </div>
            <div className="absolute h-104 md:h-full top-0 z-10 flex w-full">
                <div className="bg-opacity-0 md:w-1/3 lg:w-1/2">
                </div>
                <div className="w-full md:w-2/3 lg:w-1/2  md:max-h-screen   flex">
                    <div
                        className="relative md:max-h-screen  flex flex-col max-h-1/2 justify-end w-full text-gray-100 bg-opacity-75   md:py-8 bg-knorr md:block ">
                        <div data-aos="fade-up" data-aos-duration="1500" className="w-full max-w-2xl pb-4 pl-4 md:absolute md:top-1/2 md:-mt-20 md:pl-8 md:pb-0">
                            {preHeader && <PreHeaderElement text={preHeader} className="text-white  text-base md:text-xl lg:text-2xl" />}
                            <h1 className="mb-4 uppercase title pr-2">{header}</h1>
                        </div>
                        <div
                            className="bottom-0 hidden max-w-2xl pl-6 pr-4 mb-4 text-base md:text-xl lg:text-2xl md:flex md:absolute md:pl-8 ">
                            {arrow && <span className="text-3xl mr-2 inline-block animate-bounce text-knorr-alt ">
                                <div className="rotate-90 transform pt-2">
                                     <RightArrow className={`fill-current `}  />
                                </div>
                           
                                </span>}
                            {
                                subHeader &&
                                <RichTextElement
                                    className=""
                                    richText={subHeader} />
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}


HeroModule.propTypes = {
    ...TypeProp,
    ...IdProp,
    ...HeaderProp,
    ...SubHeaderRichTextProp,
    ...PreHeaderProp,
    backgroundImage: PropTypes.shape(FluidImageProp),
}



export const HeroModuleFragment = graphql`
     fragment HeroModuleFragment on ContentfulHeroModule {
        id
        preHeader
        header
        subHeader {
            json
        }
        backgroundImage {
            ...GatsbyFluidImageFragment
        }
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
