import React from 'react'
import { graphql } from 'gatsby';
import { useElements } from '../../utils/useElements';
import Img from 'gatsby-image'

export default function DiscoveryModule(props) {

    const { topImage, header, button, assetList } = props;
    // const elementsToRender = useElements(topImage)
    const Button = useElements(button, { alt: true })
    const AssetList = useElements(assetList, { alt: true, className:"hover:text-knorr-alt", altHover: true })
    return (
        <section className="bg-knorr bg-opacity-95 text-white discovery-module">
            <Img fluid={topImage.fluid} className="text-8xl" />

            <div className="pl-4 font-bold flex flex-col md:flex-row pt-8 pb-16 px-4 max-w-screen-xl mx-auto">
                <div className="md:w-1/2 mb-4 ">
                    <h3 className="text-3xl md:text-5xl font-normal leading-tight mb-6 ">{header}</h3>
                    <Button className="text-4xl " />
                </div>

                <aside className="md:flex flex-col md:w-1/2 hidden">
                    <h5 className="font-normal text-base mb-4">Frequently downloaded assets</h5>
                    <AssetList />
                </aside>

            </div>
        </section>
    )
}


export const DiscoveryModuleFragment = graphql`
     fragment DiscoveryModuleFragment on ContentfulDiscoveryModule {
        id
        topImage {
            ...GatsbyFluidImageFragment
        }
        header
        button {
            ...ButtonElementFragment
        }
        assetList {
            ...AssetElementFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
