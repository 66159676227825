import React from 'react'
import { graphql } from 'gatsby';
import { useElements } from '../../utils/useElements';
import HeaderElement from '../contentful-elements/HeaderElement';

export default function AssetModule({header, assetList }) {

    const Assets = useElements(assetList, {className: "w-full block border-none"})

    return (
        <section className="text-knorr">
            { header && <HeaderElement text={header} className="text-4xl md:text-6xl font-bold mb-2" />}
            <hr className="border-t  border-knorr"/>

            <div className="space-y-2 my-4 w-full">
                <Assets />
            </div>
            <hr className="border-t border-knorr"/>
        </section>
    )
}


export const AssetModuleFragment = graphql`
    fragment AssetModuleFragment on ContentfulAssetModule {
        id
        header
        assetList {
            ...AssetElementFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
