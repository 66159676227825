import React from 'react'
import { graphql } from 'gatsby';

export default function VideoModule({ caption, videoLink, videoProvider }) {
    return (
        <section className="" >
 

            <figure  className="max-w-2xl mx-auto">
            {videoProvider && videoLink && <iframe
                    width="100%"
                    className="h-48 md:h-96"
                    // height="400"
                    src={`https://www.youtube.com/embed/${videoLink}?modestbranding=1&rel=0&playsinline=0&showinfo=0&controls=2`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>}

                {!videoProvider && videoLink && <iframe
                    src={`https://player.vimeo.com/video/${videoLink}?controls=0`}
                    width="100%"
                    className="h-48 md:h-96"
                    frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>}


                <figcaption className="text-center mt-4"> {caption}</figcaption>
                </figure>
              
                    
           
            
        </section>
    )
}


export const VideoModuleFragment = graphql`
     fragment VideoModuleFragment on ContentfulVideoModule {
        id
        caption
        videoLink
        videoProvider
        # might have to add an uploaded video, or a youtube vs. another link here
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
