import React from 'react'
import { graphql } from 'gatsby';
import { useElements, getElementType } from '../../utils/useElements';
import Img from 'gatsby-image';
import PreHeaderElement from '../contentful-elements/PreHeaderElement';
import RichTextElement from '../contentful-elements/RichTextElement';

export default function TwoColumnsSpreadModule(props) {
    const { preHeader, header, description, link, bottomList, image, imageSide } = props;


    const AssetType = !!getElementType(bottomList)["assetElement"]

    const List = useElements(bottomList, AssetType ? {showImage: true} : {})
    const DesktopLink = useElements(link, { className: "hidden md:flex ", spread: true})
    const MobileLink = useElements(link, { className: "md:hidden mt-2", spread: true})


    const ListType = !!getElementType(bottomList)["listElement"]

    // Image side
    return (
        <article data-aos="fade-up" data-aos-duration="1500" className="flex flex-col h-full overflow-hidden bg-knorr-light lg:flex-row rounded">
            <div className={`hidden md:block w-full lg:w-1/2   ${!imageSide ? "lg:order-2" : " lg:order-0 "}`} >
                <Img className="max-h-1/2 lg:max-h-full" fluid={image.fluid} alt={image.title} />
            </div>
          

            <div className="grid w-full pt-4 font-bold lg:w-1/2 md:pb-8 text-knorr grid-rows-8 lg:px-4 ">


                <aside className="self-start w-full mx-4 mb-2 lg:top-0 lg:mt-4">
                    { preHeader && <PreHeaderElement text={preHeader}  /> }
                </aside>
               

                <div className="self-center w-full row-span-6 mb-4 space-y-8 md:px-4">
                { header && <h3 className="px-4 mb-4 header md:px-0">{header}</h3>}
                { description && <p className="px-4 text-xl font-thin md:px-0">{description.text}</p>}
                { bottomList && link && <DesktopLink />}
                </div>

                <div className={` hidden md:block px-4 mt-10 w-full pr-4 self-end g:mb-4 ${AssetType && "md:grid md:grid-cols-3 gap-x-3 lg:gap-x-4"}`}>
                    { bottomList && <List />} 
                    { !bottomList && <DesktopLink />} 
                </div>
            </div>

            <div className={`md:hidden w-full lg:w-1/2   ${!imageSide ? "lg:order-2" : " lg:order-0 "}`} >
                <Img className="max-h-1/2 lg:max-h-full" fluid={image.fluid} alt={image.title} />
            </div>
          
            <div className="order-3">
            { link && <MobileLink />}
            </div>
         
        </article>
    )
}


export const TwoColumnsSpreadModuleFragment = graphql`
     fragment TwoColumnsSpreadModuleFragment on ContentfulTwoColumnsSpreadModule {
        id
        preHeader
        header
        description {
            text: description
        }
        link {
            ...ButtonElementFragment
        }
        bottomList {
            ...AssetElementFragment
            ...ListElementFragment
        }
        image {
            ...GatsbyFluidImageFragment
        }
        imageSide
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
