import React from 'react'
import { graphql } from 'gatsby';
import { useElements } from '../../utils/useElements';

export default function FullWidthImageModule({ image, name }) {
    const Image = useElements(image)
    return (
        <div className="">
            <Image />
        </div>
    )
}


export const FullWidthImageModuleFragment = graphql`
     fragment FullWidthImageModuleFragment on ContentfulFullWidthImageModule {
        id
        name
        image {
            ...ImageElementFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
