import React from 'react'
import { graphql } from 'gatsby';
import RichTextElement from '../contentful-elements/RichTextElement';
import { toKebabCase } from '../../utils/General';
import IsVisible from 'react-visibility-sensor';
import { HeaderContext } from '../layout/Layout';

export default function SummaryModule({ header, text }) {


    const headerId = toKebabCase(header)


    const { setActiveHeader } = React.useContext(HeaderContext);

    const handleChange = (change) => {
        return setActiveHeader(headerId)
    }


    return (   
        <section>
            <summary className="py-8 px-6 md:px-12 bg-knorr text-white w-auto">
                {header && <IsVisible onChange={(change) => handleChange(change)}><h3 id={headerId} data-menu="true" data-text="Summary" className="text-xl md:text-3xl mb-4 font-bold">{header}</h3></IsVisible>}
                <RichTextElement className="bullets" richText={text} />
            </summary>
        </section>

    )
}


export const SummaryModuleFragment = graphql`
     fragment SummaryModuleFragment on ContentfulSummaryModule {
        id
        header
        text {
            json
        }
        sys {
            contentType {
                sys {
                    id
                }
            }
        }
    }
`
