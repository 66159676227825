import React from 'react'
import { Tooltip } from 'react-tippy';

// import 'rc-tooltip/assets/bootstrap_white.css'

export default function ToolTipWrapper({ children, visible, overlay, className }) {
    return (
        <Tooltip
            html={overlay}
            position="top"
            trigger="mouseenter"
            className={className}
            interactive={true}
        >

            { children}
        </Tooltip>
    )
}
