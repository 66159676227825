import React from 'react'
import { graphql } from 'gatsby';
import { useElements } from '../../utils/useElements';

export default function ContentModule(props) {

    const { contentList, className="space-y-12"} = props

    const ContentList = useElements(contentList)

    return (
        <section className={`content-module ${className}`}>
            <ContentList />
        </section>
    )
}


export const ContentModuleFragment = graphql`
     fragment ContentModuleFragment on ContentfulContentModule {
        id
        name
        contentList {
            ...AssetElementFragment
            ...ButtonElementFragment
            ...CarouselElementFragment
            ...HeaderElementFragment
            ...ImageElementFragment
            ...PreHeaderElementFragment
            ...RichTextElementFragment
            ...SubHeaderElementFragment
            ...TwoImagesElementFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
