import React from 'react'
import { graphql } from 'gatsby';
import { useElements } from '../../utils/useElements';
import Img from 'gatsby-image';

export default function TwoColumnsModule(props) {

    const { leftColumn, leftColumnTint, rightColumn, rightColumnTint, backgroundImage } = props;


    const columnProps = {
        imageElement: {
            className: "sm:max-w-lg lg:max-w-none mx-auto",
            wrapperClassName: ""
        }
    }
    const LeftColumn = useElements(leftColumn, columnProps)
    const RightColumn = useElements(rightColumn, columnProps)


    return (
        <div className="relative z-0 overflow-hidden h-full ">
            { backgroundImage && <figure className="absolute w-full h-full z-0">

                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 two-columns-module absolute w-full h-full">

                    <div
                        className={`
                    column
                    md:block hidden
                    space-y-4  pt-12 lg:pb-12  px-4 pb-4 lg:px-12 relative z-10
                    ${leftColumnTint && "bg-knorr bg-opacity-75"}
                    ${backgroundImage || leftColumnTint ? "text-white" : "text-knorr"}
                    `}>
                    </div>
                    <div className={`
                    column
                    md:block hidden
                    space-y-4  pt-6 lg:pt-12  px-4 pb-4  lg:px-12 relative z-10
                    ${rightColumnTint && "bg-knorr bg-opacity-75"}
                    ${backgroundImage || rightColumnTint ? "text-white" : "text-knorr"}
                    `}>
                    </div>
                </div>
                <Img className="h-full" fluid={backgroundImage.fluid} alt={backgroundImage.title} />
            </figure>}

            <section className="grid grid-cols-1 md:grid-cols-2 gap-x-6 two-columns-module">
                <div
                    className={`
                    column
                    ${!backgroundImage && "no-background "}
                    ${backgroundImage && " flex flex-col justify-center"}
                    ${leftColumnTint && "bg-knorr bg-opacity-75 md:bg-opacity-0"}
                    space-y-4  pt-12 lg:pb-12  px-4 pb-4 lg:px-12 relative z-10
                    ${backgroundImage || leftColumnTint ? "text-white" : "text-knorr"}
                    `}>
                    <LeftColumn />
                </div>
                <div className={`
                    column  
                    ${!backgroundImage && "no-background "}
                    ${backgroundImage && rightColumn.length === 1 && "pt-24 pb-24 md:pb-4 md:pt-6 md:py-0"} 
                    ${backgroundImage && `${rightColumn.length > 1 && "image-max" } flex flex-col justify-center` }
                    ${rightColumnTint && "bg-knorr bg-opacity-75  md:bg-opacity-0"}
                    space-y-4  pt-6 lg:pt-12  px-4 pb-4  lg:px-12 relative z-10 
                    ${backgroundImage || rightColumnTint ? "text-white" : "text-knorr"}
                    `}>
                    <RightColumn />
                </div>
            </section>
        </div>
    )
}


export const TwoColumnsModuleFragment = graphql`
     fragment TwoColumnsModuleFragment on ContentfulTwoColumnsModule {
        id
        name
        leftColumn {
            ...ButtonElementFragment
            ...AssetElementFragment
            ...HeaderElementFragment
            ...PreHeaderElementFragment
            ...RichTextElementFragment
            ...SubHeaderElementFragment
            ...ImageElementFragment
            ...GridImageElementFragment
        }
        leftColumnTint 
        rightColumn {
            ...ButtonElementFragment
            ...AssetElementFragment
            ...HeaderElementFragment
            ...PreHeaderElementFragment
            ...RichTextElementFragment
            ...SubHeaderElementFragment
            ...ImageElementFragment
            ...GridImageElementFragment
        }
        rightColumnTint 
        backgroundImage {
            ...GatsbyFluidImageFragment
        }
        sys {
            contentType {
                sys {
                id
                }
            }
        }
    }
`
