import React from 'react'
import Img from 'gatsby-image'
// import HoverIcon from '../general/HoverIcon';

const HoverIcon = React.lazy(() =>
    import("../general/HoverIcon")
)
export default function FrontPacketModule({ image }) {
    const isSSR = typeof window === "undefined"

    const icons = [
        {
            location: {
                top: "33%",
                left: "40%"
            },
            number: 1,
            header: "Variant Name",
            description: "Atrament Semibold, Uppercase"
        },
        {
            location: {
                top: "50%",
                left: "5%"
            },
            number: 2,
            header: "Our Promise",
            description: "Good Karma Wide, Title case"
        },
        {
            location: {
                top: "60%",
                left: "30%"
            },
            number: 3,
            header: "Claims",
            description: "Filson Soft Book & Bold, Title case"
        },
        {
            location: {
                top: "75%",
                left: "10%"
            },
            number: 4,
            header: "Shopping List",
            description: "Filson Soft Book & Bold, Title case"
        },
    ]
    return (
        <section className="static-module">
        <h3>Front of pack</h3>
        <div className=" bg-knorr bg-opacity-25 py-12 lg:py-20 ">
            <div className="w-full h-full max-w-xl px-4 mx-auto relative">
                <Img className="w-full h-full" fluid={image.fluid} />
                {icons.map(icon => !isSSR && <React.Suspense fallback={<div />}><HoverIcon key={icon.number} {...icon} /> </React.Suspense> )}
            </div>
        </div>
    </section>
    )
}