import React from 'react'
import Tooltip from 'react-power-tooltip'
  

const HoverIcon = ({ number, header, description, location = {}, position }) => {
    const isSSR = typeof window === "undefined"
    const [show, setShow] = React.useState(false)

    return <a
            data-for={header}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            data-tip
            style={{ ...location }}
            className="text-2xl bg-knorr-alt text-knorr rounded-full shadow-lg cursor-pointer absolute w-12 h-12 flex justify-center">
            <span className="self-center">{number}</span>
            {!isSSR && <HoverOver show={show} header={header} description={description} className="w-full" position={position} />}
        </a>
}


export const HoverOver = ({ header, description, id = false, className, show, position = "bottom" }) => {
    return <Tooltip
        show={show}
        hoverBackground="white"
        className={`${className} w-104`}
        aria-haspopup='true'
        position={position}
        id={id ? id : header}
    >
        <div className="hover:bg-transparent bg-white" style={{backgroundColor: "white"}}>
            <h4 className="text-xl text-knorr ">{header}</h4>
            <p className="text-base font-normal text-knorr">{description}</p>
        </div>

    </Tooltip>
}

export default HoverIcon